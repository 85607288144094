nav.navbar {
    background: whitesmoke;
    /* position: fixed; */
  }
  .brand-title{
    padding-top: 4px;
  }
  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    /* padding: 0.2rem; */
  }
  
  .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1);
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='grey' d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'%3E%3C/path%3E%3C/svg%3E");
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-size: 100% 100%;
  }
  
  ul.links-list {
    display: flex;
    flex-direction: row;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  
  li.nav-item {
    list-style-type: none;
    margin-left: 40px;
    font-size: 16px;
    font-weight: 550;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0;
    font-family: 'poppins', sans-serif; 
  }
  
  nav a {
    text-decoration: none;
    color: #333;
  }
  
  nav a.is-active {
    color: maroon;
  }
  
  .container {
   width: 80%;
   margin: 0 auto;
  }
  
  @media only screen and (min-width: 992px) {
    .navbar-toggler {
      display: none;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .navbar-toggler {
      display: block;
    }
  
    div.navbar-links {
      margin-right: 0;
      position: absolute;
      width: 100%;
      background: whitesmoke;
      z-index: 99;
      margin-top: 3.1rem;
      float: right;
      right: 0;
      height: 85vh;
      transition: all ease-in-out 0.3s;
      align-self: flex-start;
      border-top: 2px solid maroon;
      padding-bottom: 15vh;
    }
  
    ul.links-list {
      display: flex;
      flex-direction: column;
      padding-left: 0;
    }
  
    .navbar-links.menu-collapse {
      margin-right: -75%;
      width: 0;
      overflow: hidden;
    }
  
  
    li.nav-item a.nav-link {
        padding: 10px 20px;
    }
  
    .menu-collapse {
      margin-right: -75%;
      width: 0;
      overflow: hidden;
    }
    ul.links-list li>ul{
      display: block !important;
      position: static !important;
      width: 95% !important;
    }
    .navbar-links{
      overflow-y: scroll;
    }
    ul.links-list li ul li{
      text-indent: -20px;
      padding-left: 30px;
    }
  }

.contact{
  background-color: #264e86;
  color: white;
  /* font-family: 'poppins', sans-serif; */
  /* font-size: 15px; */
}

.remove-a{
  text-decoration: none;
  color: white;
  position: relative;
  top: -3px;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
}
.follow-us{
  font-size: 12px;
  text-align: center;
  align-self: center;
  justify-content: center;
}
.icon{
  position: relative;
  top: 4px;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 650px) {
  .contact{
    display: none;
  }
}

ul.links-list ul{
  display: none;
  position: absolute;
  background: whitesmoke;
  top: 45px;
  padding: 0px;
  left: 10px;
  width: 250px;
}

ul.links-list li:hover>ul{
   display: block;
}
ul.links-list ul li{
  float: none;
  display: list-item;
  padding-left: 15px;  
  padding-right: 15px;  
  /* font-weight: 400; */
}