.border-design img{
    line-height: 69px;
    background-color: #fff;
    font-size: 40px;
    color: #1890cc;
    -webkit-animation: border-transform 10s linear infinite alternate forwards;
    animation: border-transform 10s linear infinite alternate forwards;
    border-radius: 50%;
    -webkit-transition: .5s;
    transition: .5s;
    text-align: center;
    border: 1px solid #d0e1dc;
}


.hovSelect:hover span svg{
    background-color: #1890cc;
    color: #fff;
}

.hovSelect span svg{
    background-color: #eaeaf2;
    color: #252525;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: relative;
    top: 2px;
    margin-right: 4px;
}

.hovSelect{
    margin: 14px 8px;
    font-size: 15x;
}

.hovIcon span{
    /* width: 90px;
    height: 90px; */
    /* line-height: 69px; */
    background-color: #fff;
    font-size: 40px;
    color: #1890cc;
    /* -webkit-animation: border-transform 10s linear infinite alternate forwards;
    animation: border-transform 10s linear infinite alternate forwards; */
    border-radius: 50%;
    /* -webkit-transition: .5s; */
    /* transition: .5s; */
    /* text-align: center; */
    border: 1px solid #d0e1dc;
    position: relative;
    left: -50px;
    top: 80px;
    padding: 0px 4px;
}

.hovIcon:hover span{
    background-color: #1890cc;
    color: #fff;
}

.hovIcon h3{
    color: black;
    font-size:23px;
    font-weight: bold;
}

.hovIcon span svg{
    position: relative;
    top: 4px;
    width: 30px;
    height: 30px;
}

@media (max-width: 600px){
    .hovIcon span{
        font-size: 50px;
        left: -60px
    }
    .hovIcon span svg{
    width: 40px;
    height: 40px;
    }
    #AboutPic{
        width: 18rem !important;
    }
}


#AboutPic{
    width: 28rem;
}

ul.sub-list{
    /* padding: 5px; */
    margin: 0px;
}
ul.sub-list li{
    padding: 5px;
    /* text-indent: -40px; */
}
/* ul.sub-list li:active{
    background-color: rgb(24, 144, 204);
    color: white;
}

ul.sub-list li svg{
    background-color: rgb(24, 144, 204);
    color: white;
} */
.nav-link{
    text-decoration: none;
    color: black;
    font-weight: normal;
    /* background-color: #eaeaf2; */
}
ul.Page-list li{
line-height: 2.2;
font-size: 15px;
}